<template>
  <base-section id="careers-detail">
    <base-section-heading
      color="grey lighten-2"
      :title="title"
      class="mx-2"
    />

    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <template>
          <v-card
            max-width="70%"
            class="mx-auto"
            v-for="data in posts"
            :key="data.web_career_id"
            flat
          >
            <v-list-item>
              <v-list-item-avatar><v-img :src="require(`@/assets/logo-orion-new.jpeg`)" :alt="data.web_career_department_name"></v-img></v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="accent--text font-weight-black">{{ data.web_career_category_desc }}</v-list-item-title>
                <v-list-item-subtitle>{{ data.web_career_department_name }}</v-list-item-subtitle>
                <v-list-item-subtitle><small>Expired: {{ FormatDateIndo(data.web_career_edate) }}</small></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-alert
              border="top"
              colored-border
              color="primary"
              class="grey lighten-4 body-2 text-justify"
            >
            <div v-html="data.web_career_detail"></div>
            </v-alert>
            <v-card-actions>
                <v-btn color="accent" block class="white--text" rounded @click="Dialog">Apply</v-btn>
            </v-card-actions>
          </v-card>
        </template>
        </v-col>
      </v-row>
      <v-speed-dial
          v-model="fab"
          :top="true"
          :left="true"
          direction="bottom"
          transition="slide-y-reverse-transition"
          style="position: fixed; top: 164px; left: -1px;"
          >
          <template v-slot:activator>
            <v-btn
              v-model="fab"
              color="accent"
              outlined
            >
              <v-icon v-if="fab">mdi-close</v-icon>
              <v-icon v-else>mdi-share</v-icon>
              <span class="hidden-xs-only">Bagikan</span>
            </v-btn>
          </template>
          <ShareNetwork
            v-for="network in networks"
            :network="network.network"
            :key="network.network"
            :url="sharing.url"
            :title="sharing.title"
            :hashtags="sharing.hashtags"
          >
            <v-btn fab color="accent"><v-icon :color="network.color" x-large>{{ network.icon }}</v-icon></v-btn>
          </ShareNetwork>
        </v-speed-dial>
        <v-dialog
          v-model="dialog"
          scrollable 
          fullscreen
          persistent 
          :overlay="false"
          transition="dialog-transition"
        >
          <v-card>
            <v-card-title>
              <base-title
                :title="`${ title }`"
                class="text-uppercase"
              />
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn outlined @click="Confirm"><v-icon>mdi-content-save</v-icon>Submit</v-btn>
              <v-btn outlined @click="dialog = false"><v-icon>mdi-close</v-icon>Tutup</v-btn>
            </v-card-actions>
            <v-card-text>
                <v-form 
                  enctype="multipart/form-data"
                  ref="form_career"
                  lazy-validation>
                  <input type="file" ref="avatar" @change="UploadAvatar" accept="image/*" style="display:none">
                <input type="file" ref="attachment" @change="UploadAttachment" accept="application/pdf" style="display:none">
              <v-avatar
                color="primary"
                size="100"
                class="ma-2 cursor"
                @click="Upload"
              >
                <img 
                  :src="careers.avatar_url" 
                  v-if="careers.avatar !== '' "
                >
                <span class="white--text text-h6" v-if="careers.avatar === ''">Upload Foto</span>
              </v-avatar>
                  <v-text-field
                    v-model="careers.name"
                    id="careers_name"
                    label="Nama"
                    placeholder="Nama Lengkap Anda"
                    prepend-inner-icon="mdi-account"
                    filled
                    clearable
                    required
                    :rules="[v => !!v || 'Mohon isi nama lengkap anda']"
                  ></v-text-field>
                  <v-btn-toggle 
                  v-model="careers.gender"
                  class="mb-5"
                  active-class="accent"
                  >
                  <v-btn>
                    <span>Pria</span>
                    <v-icon>mdi-human-male</v-icon>
                  </v-btn>

                  <v-btn>
                    <span>Wanita</span>
                    <v-icon>mdi-human-female</v-icon>
                  </v-btn>
                  </v-btn-toggle>
                  <v-text-field
                    v-model="careers.email"
                    label="Email"
                    placeholder="Email Anda"
                    prepend-inner-icon="mdi-email"
                    filled
                    clearable
                    required
                    :rules="careers.email_rules"
                  ></v-text-field>
                  <v-text-field
                    v-model="careers.mobile"
                    label="No HP"
                    placeholder="No HP Anda"
                    prepend-inner-icon="mdi-cellphone"
                    filled
                    clearable
                    required
                    :rules="[v => !!v || 'Mohon isi nomor HP anda']"
                  ></v-text-field>
                  <v-btn-toggle
                  v-model="careers.type"
                  class="mb-5"
                  active-class="accent"
                  >
                  <v-btn>
                    <span>Fresh Graduate</span>
                    <v-icon>mdi-school</v-icon>
                  </v-btn>

                  <v-btn>
                    <span>Pengalaman</span>
                    <v-icon>mdi-briefcase-check</v-icon>
                  </v-btn>
                  </v-btn-toggle>
                  <div></div>
                  <v-btn-toggle
                  v-model="careers.industry"
                  class="mb-5"
                  active-class="accent"
                  v-if="careers.type === 1"
                  >
                  <v-btn>
                    <span>Asuransi</span>
                    <v-icon>mdi-shield</v-icon>
                  </v-btn>

                  <v-btn>
                    <span>Lainnya</span>
                    <v-icon>mdi-shape</v-icon>
                  </v-btn>
                  </v-btn-toggle>
                  <v-row
                  v-if="(careers.industry === 0 || careers.industry === 1) && careers.type === 1"
                  >
                  <v-col
                  cols="12"
                  sm="12"
                  md="5"
                >
                  <v-text-field
                    v-model="careers.company"
                    label="Nama Perusahaan Terakhir Bekerja"
                    placeholder="Nama Perusahaan Terakhir Bekerja"
                    filled
                    clearable
                    :rules="careers.industry?[v => !!v || 'Mohon isi nama perusahaan terakhir bekerja']:''"
                  ></v-text-field>
                  </v-col>
                  <v-col
                  cols="12"
                  sm="12"
                  md="3"
                >
                  <v-text-field
                    v-model="careers.title"
                    label="Jabatan Terakhir"
                    placeholder="Jabatan Terakhir"
                    filled
                    clearable
                    :rules="careers.industry?[v => !!v || 'Mohon isi jabatan terakhir bekerja']:''"
                  ></v-text-field>
                  </v-col>
                  <v-col
                  cols="6"
                  sm="6"
                  md="2"
                >
                  <v-text-field
                    v-model="careers.fromyear"
                    label="Dari Tahun"
                    placeholder="Dari Tahun"
                    filled
                    clearable
                    :rules="careers.industry?[v => !!v || 'Mohon isi tahun mulai karir anda']:''"
                  ></v-text-field>
                  </v-col>
                  <v-col
                  cols="6"
                  sm="6"
                  md="2"
                >
                  <v-text-field
                    v-model="careers.toyear"
                    label="Sampai Tahun"
                    placeholder="Sampai Tahun"
                    filled
                    clearable
                    :rules="careers.industry?[v => !!v || 'Mohon isi tahun terakhir, jika masih sampai sekarang mohon isi tahun sekarang']:''"
                  ></v-text-field>
                  </v-col>
                </v-row>
                <v-row
                  >
                  <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-radio-group
                    v-model="careers.education"
                    row
                  >
                  <label>Pendidikan Terakhir</label>
                  <v-radio
                      v-for="data in education"
                      :key = "data.id"
                      :label="data.id"
                      :value="data.id"
                    ></v-radio>
                  </v-radio-group>
                  </v-col>
                  </v-row>
                  <v-row
                  >
                  <v-col
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <v-text-field
                    v-model="careers.major"
                    label="Jurusan"
                    placeholder="Jurusan"
                    filled
                    clearable
                    :rules="careers.education?[v => !!v || 'Mohon isi jurusan terakhir']:''"
                  ></v-text-field>
                  </v-col>
                  <v-col
                  cols="6"
                  sm="6"
                  md="3"
                >
                  <v-text-field
                    v-model="careers.from"
                    label="Dari Tahun"
                    placeholder="Dari Tahun"
                    filled
                    clearable
                    :rules="careers.education?[v => !!v || 'Mohon isi tahun mulai pendidikan']:''"
                  ></v-text-field>
                  </v-col>
                  <v-col
                  cols="6"
                  sm="6"
                  md="3"
                >
                  <v-text-field
                    v-model="careers.to"
                    label="Sampai Tahun"
                    placeholder="Sampai Tahun"
                    filled
                    clearable
                    :rules="careers.education?[v => !!v || 'Mohon isi tahun selesai pendidikan']:''"
                  ></v-text-field>
                  </v-col>
                </v-row>
                  <v-text-field
                    v-model.lazy="careers.expsalary"
                    v-money="format_money"
                    label="Gaji yang di harapkan"
                    placeholder="Masukkan nominal gaji yang diharapkan"
                    prepend-inner-icon="mdi-cash"
                    filled
                    clearable
                    required
                    :rules="[v => !!v || 'Mohon masukkan nominal gaji yang diharapkan']"
                  ></v-text-field>
                  <v-text-field
                    :value="careers.attachment.name"
                    label="Upload CV Anda (PDF)"
                    prepend-inner-icon="mdi-paperclip"
                    filled
                    clearable
                    required
                    :rules="[v => !!v || 'Mohon lampirkan CV anda']"

                    :readonly="true"
                    @click="Attachment"
                  ></v-text-field>
                </v-form> 
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="confirm"
          scrollable 
          persistent :overlay="false"
          max-width="500px"
          transition="dialog-transition"
        >
          <v-card>
            <v-card-title class="primary white--text">
              <base-title
                title="Submit Data?"
                class="text-uppercase"
              />
            </v-card-title>
            <v-card-text>
              
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn outlined @click="confirm = false"><v-icon>mdi-close</v-icon>Tidak</v-btn>
              <v-btn outlined @click="Save" :disabled="confirm_yes !== 'Ya'"><v-icon>mdi-check</v-icon>{{ confirm_yes }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-snackbar
          v-model="snackbar"
          vertical
          top
          color="fourth"
        >
          {{ snackbar_text }}
          <v-btn outlined color="primary" @click.native="snackbar = false">Close</v-btn>
        </v-snackbar>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionCareersDetail',
    data: () => ({
          dialog: false,
          confirm: false,
          confirm_yes: 'Ya',
          title: '',
          fab: false,
          posts: [],
          sharing: {
            url: '',
            title: '',
            hashtags: 'takafulumum,asuransisyariah,karirtakafulumum',
          },
          networks: [
            { network: 'email', name: 'Email', icon: 'mdi-email', color: 'white' },
            { network: 'facebook', name: 'Facebook', icon: 'mdi-facebook', color: 'white' },
            { network: 'telegram', name: 'Telegram', icon: 'mdi-telegram', color: 'white' },
            { network: 'twitter', name: 'Twitter', icon: 'mdi-twitter', color: 'white' },
            { network: 'whatsapp', name: 'Whatsapp', icon: 'mdi-whatsapp', color: 'white' },
          ],
          careers: {
            name: '',
            gender: 0,
            type: 0,
            email: '',
            mobile: '',
            upload: null,
            email_rules: [
              v => !!v || 'Mohon isi email anda',
              v => /.+@.+/.test(v) || 'Email harus valid'
            ],
            avatar: '',
            avatar_url: '',
            attachment: '',
            attachment_url: '',
            education: 'SMA',
            major: '',
            company: '',
            title: '',
            fromyear: '',
            toyear: '',
          },
          snackbar: false,
          snackbar_text: '',
          //Format Number
          format_money: {
              decimal: ',',
              thousands: '.',
              precision: 0
          },
          education: [
            {id:'SMA'},
            {id:'SMK'},
            {id:'D3'},
            {id:'S1'},
            {id:'S2'},
            {id:'S3'},
          ]
      }),
    created () {
      this.List()
    },
    beforeRouteLeave (to, from, next) {
        if (this.dialog || this.confirm) {
            this.dialog = false
            this.confirm = false
            next(false)
        } else {
            next()
        }
    },
    watch: {
        "$route.params.url"(val) {
          console.log(val)
          this.List();
        },
        title: function (val, old) {
            console.log(old)
            document.title     = val
            this.sharing.title = this.$functions.AppURL() +""+ this.$router.currentRoute.path
        }
    },
    methods: {
      List () {
        this.loading  = true
        let title = this.$route.params.url
        let formdata = {
              web_career_id: title
        }
        let param      = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiWebCareerList'),param,{
        headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
        })
        .then(response => {
            this.posts = response.data
            this.title = this.posts[0].web_career_name
            this.loading  = false
        })
        .catch(e => {
            alert(e)
            this.loading  = false
        })
      },
      FormatDateIndo (date) {
        return this.$functions.FormatDateIndo(date)
      },
      Dialog () {
        this.dialog = true
        setTimeout(function(){
        document.getElementById('careers_name').focus()
        }, 500)
      },
      Confirm () {
        if (this.$refs.form_career.validate()) {
          let validation = ''
          if (this.careers.avatar === '') {
            validation = 'Mohon upload avatar'
          }
          if (this.careers.upload === '') {
            validation = 'Mohon upload CV'
          }
          if (this.careers.gender === '') {
            validation = 'Mohon pilih Gender'
          }
          if (this.careers.type === '') {
            validation = 'Mohon pilih Tipe'
          }
          if (this.careers.education === '') {
            validation = 'Mohon pilih Pendidikan'
          }
          if (this.careers.industry === '') {
            validation = 'Mohon pilih Industri'
          }
          if (validation !== '') {
            alert(validation)
          } else {
            this.confirm = true
          }
        }
      },
      Save () {
        this.confirm_yes = 'Loading...'
        let formdata = {
              url_type: 'add',
              web_careerform_id: this.$route.params.url,
              web_careerform_refno: this.$route.params.url,
              web_careerform_name: this.careers.name,
              web_careerform_gender: this.Flag(this.careers.gender,'gender'),
              web_careerform_mobile: this.careers.mobile,
              web_careerform_type: this.Flag(this.careers.type,'type'),
              web_careerform_industry: this.Flag(this.careers.industry,'industry'),
              web_careerform_email: this.careers.email,
              web_careerform_from: this.careers.from,
              web_careerform_to: this.careers.to,
              web_careerform_major: this.careers.major,
              web_careerform_company: this.careers.company,
              web_careerform_title: this.careers.title,
              web_careerform_fromyear: this.careers.fromyear,
              web_careerform_toyear: this.careers.toyear,
              web_careerform_expsalary: this.careers.expsalary,
              web_careerform_education: this.careers.education,
              web_careerform_avatar: this.careers.avatar_url.replace(/^.+?;base64,/, ''),
              web_careerform_attachment: this.careers.attachment_url.replace(/^.+?;base64,/, ''),
        }
        let param      = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiWebCareerWrite'),param,{
        headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
        })
        .then(response => {
            let getdata          = response.data
            if (getdata[0].feedback === 'Y') {
              this.snackbar_text  = 'Terimakasih data anda telah kami terima'
              this.dialog         = false
              this.confirm        = false
              this.snackbar       = true
              this.confirm_yes    = 'Ya'
            } else {
              this.snackbar_text  = getdata[0].feedback
              this.snackbar       = true
              this.confirm_yes    = 'Ya'
            }
        })
        .catch(e => {
            alert(e)
            this.loading      = false
            this.confirm      = false
            this.confirm_yes  = 'Ya'
        })
      },
      Upload () {
        this.$refs.avatar.click()
      },
      UploadAvatar () {
        var input = event.target
        if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = (e) => {
          this.careers.avatar_url = e.target.result
        }
        this.careers.avatar = input.files[0]
        reader.readAsDataURL(input.files[0])
        }
      },
      Attachment () {
        this.$refs.attachment.click()
      },
      UploadAttachment () {
        var input = event.target
        if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = (e) => {
          this.careers.attachment_url = e.target.result
        }
        this.careers.attachment = input.files[0]
        reader.readAsDataURL(input.files[0])
        }
      },
      Flag (id, type) {
        let feedback = ''
        if (type === 'gender') {
          if (id === 0) {
            feedback = 'M'
          } else if (id === 1) {
            feedback = 'F'
          }
        } else if (type === 'type') {
          if (id === 0) {
            feedback = 'F'
          } else if (id === 1) {
            feedback = 'E'
          }
        } else if (type === 'industry') {
          if (id === 0) {
            feedback = 'I'
          } else if (id === 1) {
            feedback = 'O'
          }
        }
        return feedback
      }
    }
  }
</script>